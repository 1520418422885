import { Clear } from '@mui/icons-material';
import {
    FormControl,
    Select,
    MenuItem,
    InputLabel,
    Box,
    Chip,
    ListItemText,
    Checkbox,
    ListSubheader,
    FormHelperText,
    SelectChangeEvent,
    BaseSelectProps,
    IconButton,
} from '@mui/material';
import { PropsWithChildren, useState, useEffect } from 'react';

export interface DictionarySelectPublicProps<T extends string | number, T2> extends BaseSelectProps<T | T[] | null> {
    checkboxes?: boolean;
    chips?: boolean;
    helperText?: string | false;
    disableFullwidth?: boolean;
    disableEmpty?: boolean;
    filterOptions?: (options: T2[]) => T2[];
    highlightWhenFilled?: boolean;
    enableClear?: boolean;
}

export interface DictionarySelectProps<T extends string | number, T2> extends DictionarySelectPublicProps<T, T2> {
    getOptionLabel: (option: T, dicts: T2[] | undefined) => string;
    getDictValues: (dicts: T2[]) => T[];
    getDictOptions: Promise<T2[]>;
    separatorBeforeIndex?: number;
    separatorText?: string;
    getSubcategoryLevel?: (option: T, dicts: T2[] | undefined) => number;
    renderOptionSuffix?: (option: T) => JSX.Element | null;
}

/**
 * Generic select component for selecting values from a dictionary. The dictionary values are loaded
 * via the provided `getDictOptions` promise. The dictionary values are then transformed into an array
 * of values using the provided `getDictValues` function and used as options. Labels for the options
 * are generated using the provided `getOptionLabel` function.
 *
 * The component can be used as a single select or a multiple select. The `multiple` property is used
 * to control this.
 *
 * @param props Component properties extending the `SelectProps` interface.
 * @param props.getOptionLabel Function for generating labels for the options.
 * @param props.getDictValues Function for transforming the dictionary values into an array of values.
 * @param props.getDictOptions Promise for loading the dictionary values.
 * @param props.checkboxes Whether to display the options as checkboxes.
 * @param props.chips Whether to display the selected options as chips.
 *
 * @returns
 */
const DictionarySelect = <T extends string | number, T2>(props: PropsWithChildren<DictionarySelectProps<T, T2>>) => {
    const [dicts, setDicts] = useState<T2[] | undefined>();

    useEffect(() => {
        props.getDictOptions.then((dicts) => setDicts(props.filterOptions ? props.filterOptions(dicts) : dicts));
    }, [props.getDictOptions]);

    const options = props.getDictValues(dicts || []);

    const selectProps: any = { ...props };
    delete selectProps.getOptionLabel;
    delete selectProps.getDictValues;
    delete selectProps.getDictOptions;
    delete selectProps.checkboxes;
    delete selectProps.chips;
    delete selectProps.separatorBeforeIndex;
    delete selectProps.separatorText;
    delete selectProps.helperText;
    delete selectProps.disableFullwidth;
    delete selectProps.getSubcategoryLevel;
    delete selectProps.disableEmpty;
    delete selectProps.filterOptions;
    delete selectProps.highlightWhenFilled;
    delete selectProps.enableClear;
    delete selectProps.renderOptionSuffix;

    // this is a fix for initial render where the options are not yet loaded
    // if we supplied the value, Select would throw a warning that the supplied
    // value is not in the options, so we supply an empty string instead
    const value = options.length > 0 ? props.value : '';
    const notEmpty = Array.isArray(value) ? !!(value as T[]).length : !!value;
    const displayClearIcon = props.enableClear && notEmpty;

    return (
        <FormControl fullWidth={!props.disableFullwidth} size={selectProps.size} error={props.error}>
            <Select<T | T[]>
                {...selectProps}
                value={value || (props.multiple ? [] : '')}
                onChange={(event, child) => {
                    if (!props.onChange) return;

                    // if value is not empty, call onChange
                    if (props.multiple ? (event.target.value as T[]).length > 0 : !!event.target.value)
                        return props.onChange(event, child);

                    // if value is empty, call onChange with value set to null
                    props.onChange(
                        {
                            ...event,
                            target: {
                                ...event.target,
                                value: null,
                            },
                        } as unknown as SelectChangeEvent<T | T[] | null>,
                        child,
                    );
                }}
                sx={{
                    '&.hasClearIcon': {
                        '&.Mui-focused, &:hover': {
                            '& .MuiInputBase-input': {
                                paddingRight: '67px',
                            },
                            '& .clearButton': {
                                display: 'flex',
                            },
                        },
                    },
                }}
                className={[
                    selectProps.className,
                    props.highlightWhenFilled && notEmpty ? 'nonEmpty' : '',
                    displayClearIcon ? 'hasClearIcon' : '',
                ].join(' ')}
                endAdornment={
                    <>
                        {!!displayClearIcon && (
                            <IconButton
                                size="small"
                                sx={{
                                    position: 'absolute',
                                    right: '45px',
                                    top: '50%',
                                    transform: 'translateY(-50%)',
                                    display: 'none',
                                }}
                                className="clearButton"
                                onClick={() => {
                                    props.onChange?.(
                                        {
                                            target: {
                                                value: props.multiple ? null : ('' as T),
                                                name: props.name as string,
                                            } as EventTarget & { value: T | T[]; name: string },
                                        } as unknown as SelectChangeEvent<T | T[] | null>,
                                        <></>,
                                    );
                                }}
                            >
                                <Clear />
                            </IconButton>
                        )}
                        {selectProps.endAdornment}
                    </>
                }
                renderValue={
                    props.chips
                        ? (selected) => (
                              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                  {(Array.isArray(selected) ? selected : [selected]).map((value) => (
                                      <Chip
                                          size="small"
                                          key={value}
                                          label={props.getOptionLabel(value, dicts) || 'Loading...'}
                                          onMouseDown={(e) => {
                                              e.preventDefault();
                                              e.stopPropagation();
                                          }}
                                          onDelete={() => {
                                              const newValue = (props.value as T[]).filter((v) => v !== value);
                                              props.onChange?.(
                                                  {
                                                      target: {
                                                          value: newValue.length > 0 ? newValue : null,
                                                          name: props.name as string,
                                                      } as EventTarget & { value: T | T[]; name: string },
                                                  } as unknown as SelectChangeEvent<T | T[] | null>,
                                                  <></>,
                                              );
                                          }}
                                      />
                                  ))}
                              </Box>
                          )
                        : (selected) =>
                              (Array.isArray(selected) ? selected : [selected])
                                  .map((s) => props.getOptionLabel(s, dicts) || 'Loading...')
                                  .join(', ')
                }
            >
                {!props.disableEmpty && (
                    <MenuItem value={''}>
                        <em>nezadáno</em>
                    </MenuItem>
                )}
                {options
                    .map((option, index) => [
                        ...(index === props.separatorBeforeIndex && props.separatorText
                            ? [
                                  <ListSubheader
                                      sx={{
                                          backgroundColor: 'var(--mui-palette-grey-100)',
                                          lineHeight: 'normal',
                                          py: 1.5,
                                      }}
                                      key={'separator'}
                                  >
                                      <strong>{props.separatorText}</strong>
                                  </ListSubheader>,
                              ]
                            : []),
                        <MenuItem
                            key={option}
                            value={option}
                            sx={{
                                py: props.checkboxes ? 0 : 0.5,
                                display: option === 'new' ? 'none' : undefined,
                                maxWidth: '70vw',
                                overflow: 'hidden',
                                pl:
                                    (props.getSubcategoryLevel && props.getSubcategoryLevel(option, dicts) * 5) ||
                                    undefined,
                                '& .MuiListItemText-root .MuiTypography-root': {
                                    whiteSpace: 'normal',
                                    fontWeight: props.getSubcategoryLevel
                                        ? props.getSubcategoryLevel(option, dicts) > 0
                                            ? 300
                                            : 500
                                        : undefined,
                                },
                            }}
                            color={selectProps.color}
                        >
                            {props.multiple && props.checkboxes && (
                                <Checkbox
                                    checked={((props.value || []) as T[]).includes(option)}
                                    size="small"
                                    color={selectProps.color}
                                />
                            )}
                            <ListItemText>{props.getOptionLabel(option, dicts) || 'Loading...'}</ListItemText>
                            {props.renderOptionSuffix?.(option)}
                        </MenuItem>,
                    ])
                    .flat()}
            </Select>
            <InputLabel id={props.name + '-label'} color={selectProps.color}>
                {props.label}
            </InputLabel>
            {props.helperText && <FormHelperText>{props.helperText}</FormHelperText>}
        </FormControl>
    );
};

export default DictionarySelect;
