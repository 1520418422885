import { Box, SxProps, useMediaQuery, Tooltip, IconButton } from '@mui/material';
import { FunctionComponent, useEffect, useState } from 'react';
import { IListItem } from '../schemas/interfaces';
import { DataGrid, GridColDef, GridSortModel } from '@mui/x-data-grid';
import TableIcons from './TableIcons';
import SexFromDict from './dicts/SexFromDict';
import AgeFromDict from './dicts/AgeFromDict';
import ActivityFromDict from './dicts/ActivityFromDict';
import TaxonFromDict from './dicts/TaxonFromDict';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { ListItemTags } from '../schemas/enums';
import ItemRarityIcon from './grid/ItemRarityIcon';
import UndefinedDictValue from './dicts/UndefinedDictValue';
import doubleRowStyle from './dataGrid/doubleRowStyle';
import horizontalScrollShadowStyle from './dataGrid/horizontalScrollShadowStyle';
import { useDict } from '../services/dictContext';
import { TaxonLanguage, translateTaxonName, useTaxonLanguage } from '../services/taxonLanguageContext';
import TaxonLanguageSwitcher from './formControls/TaxonLanguageSwitcher';
import { parseCountString } from '../services/parsers';

const ObsItemsTable: FunctionComponent<{
    items: IListItem[];
    hideColumns?: (keyof IListItem)[];
    sx?: SxProps;
}> = ({ items, hideColumns, sx }) => {
    const hide = hideColumns || [];
    const isMobile = useMediaQuery('(pointer: coarse)');
    const tableDoubleRow = useMediaQuery('(pointer: coarse) and (max-width: 500px)');
    const [sortModel, setSortModel] = useState<GridSortModel>();
    const [raritySortLabel, setRaritySortLabel] = useState<string>('Seřadit dle vzácnosti');
    const [taxonSortLabel, setTaxonSortLabel] = useState<string>('Seřadit od A do Z');
    const [countSortLabel, setCountSortLabel] = useState<string>('Seřadit vzezestupně');
    const { currentLanguage } = useTaxonLanguage();
    const { taxons } = useDict();
    const [nameSortLanguage, setNameSortLanguage] = useState<TaxonLanguage | undefined>(currentLanguage);

    useEffect(() => {
        if (!sortModel) return;

        const sort = sortModel[0];

        if (!sort) {
            setRaritySortLabel('Seřadit dle vzácnosti');
            setTaxonSortLabel('Seřadit od A do Z');
            setCountSortLabel('Seřadit vzestupně');
            return;
        }

        if (sort.field === 'rarity' && sort.sort) {
            setRaritySortLabel(`Seřadit dle pořadí ve vycházce`);
        } else {
            setRaritySortLabel('Seřadit dle vzácnosti');
        }

        if (sort.field === 'taxonId' && sort.sort) {
            setTaxonSortLabel(`Seřadit od ${sort.sort === 'desc' ? 'A do Z' : 'Z do A'}`);
        } else {
            setTaxonSortLabel('Seřadit od A do Z');
        }

        if (sort.field === 'count' && sort.sort) {
            setCountSortLabel(`Seřadit ${sort.sort === 'desc' ? 'vzestupně' : 'sestupně'}`);
        } else {
            setCountSortLabel('Seřadit vzestupně');
        }
    }, [sortModel]);

    const columns: GridColDef<IListItem>[] = [
        {
            id: 'rarity',
            field: 'rarity',
            headerName: '',
            flex: 0.05,
            sortable: true,
            cellClassName: 'rarity',
            headerClassName: 'rarity',
            align: 'center',
            type: 'number',
            minWidth: 40,
            sortingOrder: ['desc', null],
            valueGetter: (_, row) => {
                switch (row.rarity) {
                    case 'remarkable':
                        return 1;
                    case 'uncommon':
                        return 2;
                    case 'rare':
                        return 3;
                    default:
                        return 0;
                }
            },
            renderCell: (params) => {
                return <ItemRarityIcon item={params.row} />;
            },
        } as GridColDef<IListItem>,
        {
            id: 'taxonId',
            field: 'taxonId',
            headerName: 'Druh',
            cellClassName: 'taxonId',
            headerClassName: 'taxonId',
            flex: 2.2,
            minWidth: 200,
            sortable: true,
            sortingOrder: ['asc', 'desc'],
            type: 'string',
            valueGetter: (_, row) => {
                const taxon = taxons?.find((taxon) => taxon.id === row.taxonId);
                if (!taxon) return 'Loading...';
                return translateTaxonName(taxon, nameSortLanguage || 'cs');
            },
            renderHeader: (params) => {
                return (
                    <Box
                        sx={{
                            display: 'flex',
                            fontWeight: 'bold',
                            alignItems: 'center',
                        }}
                        className="MuiDataGrid-cellContent"
                    >
                        {params.colDef.headerName}
                        <TaxonLanguageSwitcher
                            chipProps={
                                sortModel?.[0]?.field === 'taxonId'
                                    ? { sx: { ml: 0.5, mr: 0.2, '& .MuiChip-label': { px: 0.2 } } }
                                    : undefined
                            }
                        />
                    </Box>
                );
            },
            renderCell: (params) => (
                <TaxonFromDict
                    taxonId={params.row.taxonId}
                    uncertain={params.row.tags?.includes(ListItemTags.uncertain)}
                    adviceRequested={params.row.tags?.includes(ListItemTags.adviceRequested)}
                    link={`/item/${params.row.id}`}
                    boxProps={{
                        sx: {
                            fontWeight: 'bold',
                        },
                    }}
                />
            ),
        } as GridColDef<IListItem>,
        {
            id: 'count',
            field: 'count',
            headerName: 'Počet',
            cellClassName: 'count',
            headerClassName: 'count',
            flex: 1.4,
            minWidth: 80,
            sortable: true,
            sortingOrder: ['asc', 'desc'],
            type: 'number',
            sortComparator: (v1: IListItem['count'], v2: IListItem['count']) => {
                if (!v1 && !v2) return 0;
                if (!v1) return -1;
                if (!v2) return 1;

                console.log(v1, parseCountString(v1));
                const parsed1 = parseCountString(v1);
                const parsed2 = parseCountString(v2);

                if (!parsed1 && !parsed2) return 0;
                if (!parsed1) return -1;
                if (!parsed2) return 1;

                const max1 = Math.max(parsed1.max || 0, parsed1.min || 0);
                const max2 = Math.max(parsed2.max || 0, parsed2.min || 0);

                if (max1 !== max2) {
                    return max1 - max2;
                }

                return parsed1.sortValue - parsed2.sortValue;
            },
            renderCell: (params) => params.row.count || <UndefinedDictValue />,
        } as GridColDef<IListItem>,
        {
            id: 'sexId',
            field: 'sexId',
            headerName: 'Pohlaví',
            cellClassName: 'sexId',
            headerClassName: 'sexId',
            flex: 1,
            minWidth: 90,
            sortable: false,
            type: 'number',
            renderCell: (params) => <SexFromDict sexId={params.value} />,
        } as GridColDef<IListItem>,
        {
            id: 'ageId',
            field: 'ageId',
            headerName: 'Věk',
            cellClassName: 'ageId',
            headerClassName: 'ageId',
            flex: 1,
            minWidth: 100,
            sortable: false,
            type: 'number',
            renderCell: (params) => <AgeFromDict ageId={params.value} />,
        } as GridColDef<IListItem>,
        {
            id: 'activityId',
            field: 'activityId',
            headerName: 'Aktivita',
            cellClassName: 'activityId',
            headerClassName: 'activityId',
            flex: 1,
            minWidth: 150,
            sortable: false,
            type: 'number',
            renderCell: (params) => <ActivityFromDict activityId={params.value} />,
        } as GridColDef<IListItem>,
        {
            id: 'attributes',
            field: 'attributes',
            headerName: '',
            cellClassName: 'attributes',
            headerClassName: 'attributes',
            flex: 2,
            minWidth: isMobile ? 170 : 130,
            sortable: false,
            type: 'actions',
            renderCell: (params) => {
                if (!params.row) return null;

                return (
                    <TableIcons
                        media={params.row.media}
                        itemId={params.row.id}
                        commentsCount={params.row.commentsCount ?? undefined}
                        lastCommented={params.row.lastCommented ? params.row.lastCommented : undefined}
                        note={params.row.note}
                    />
                );
            },
        } as GridColDef<IListItem>,
    ].filter((column) => hide.indexOf(column.field as keyof IListItem) === -1);

    return (
        <Box className="ObsItemsTable">
            <DataGrid
                rows={items}
                columns={columns}
                disableColumnMenu
                rowHeight={tableDoubleRow ? 61 : undefined}
                disableVirtualization={tableDoubleRow}
                pageSizeOptions={[20, 50, 100]}
                sortingOrder={['desc', 'asc', null]}
                // sorting language should change only when the sort is changed, so
                // changing the language using the switcher doesn't affect the sort
                // of the table
                // to achieve this we need to update the sort language and only after
                // that we can update the sort model
                onSortModelChange={(rules) => {
                    setNameSortLanguage(currentLanguage);

                    setTimeout(() => setSortModel(rules));
                }}
                sortModel={sortModel}
                autoHeight
                initialState={{ pagination: { paginationModel: { pageSize: 20 } } }}
                onRowClick={(params) => {
                    if (isMobile) return;

                    window.open(`${window.location.origin}/item/${params.row.id}`);
                }}
                sx={{
                    ...sx,
                    '& .MuiDataGrid-row': {
                        cursor: 'pointer',
                        '&.rarity-remarkable': {
                            backgroundColor: '#f3f5f6',
                        },
                        '&.rarity-uncommon': { backgroundColor: '#dae5ef' },
                        '&.rarity-rare': { backgroundColor: '#f8bc88' },
                        '&.rarity-remarkable, &.rarity-uncommon, &.rarity-rare': {
                            '& .MuiDataGrid-cell.taxonId': {
                                fontWeight: 'bold',
                            },
                        },
                    },
                    '& .MuiDataGrid-cell:focus, & .MuiDataGrid-columnHeader:focus, \
                    & .MuiDataGrid-cell:focus-within, & .MuiDataGrid-columnHeader:focus-within, \
                    & .MuiDataGrid-cell:active, & .MuiDataGrid-columnHeader:active': {
                        outline: 'none',
                    },
                    '& .MuiDataGrid-cell.rarity': {
                        display: 'flex',
                    },
                    '& .MuiTablePagination-select': {
                        paddingRight: '32px !important',
                    },
                    '& .MuiDataGrid-columnHeader:hover, & .MuiDataGrid-columnHeader.Mui-focused': {
                        '& .fakeSortButton': {
                            display: 'inline-flex',
                        },
                    },
                    '& .MuiDataGrid-iconButtonContainer': {
                        display: 'none',
                    },
                    ...horizontalScrollShadowStyle,
                    ...(tableDoubleRow
                        ? {
                              ...doubleRowStyle,
                              '& .MuiDataGrid-cell.rarity, \
                                  & .MuiDataGrid-columnHeader.rarity': {
                                  width: '20% !important',
                                  order: 1,
                                  alignContent: 'center',
                                  '& > .MuiBox-root': {
                                      justifyContent: 'center',
                                  },
                              },
                              '& .MuiDataGrid-cell.taxonId, \
                                  & .MuiDataGrid-columnHeader.taxonId': {
                                  width: '80% !important',
                                  order: 0,
                              },
                              '& .MuiDataGrid-cell.count, \
                                  & .MuiDataGrid-columnHeader.count': {
                                  width: '40% !important',
                                  order: 2,
                                  textAlign: 'left',
                                  '& .MuiDataGrid-columnHeaderTitleContainer': {
                                      flexDirection: 'row',
                                  },
                              },
                              '& .MuiDataGrid-cell.sexId, \
                                  & .MuiDataGrid-columnHeader.sexId': {
                                  width: '40% !important',
                                  order: 3,
                                  textAlign: 'left',
                                  '& .MuiDataGrid-columnHeaderTitleContainer': {
                                      flexDirection: 'row',
                                  },
                              },
                              '& .MuiDataGrid-cell.attributes, \
                                  & .MuiDataGrid-columnHeader.attributes': {
                                  width: '20% !important',
                                  order: 4,
                                  '& > .MuiBox-root': {
                                      justifyContent: 'center',
                                  },
                              },
                              '& .MuiDataGrid-cell .gallery-button, \
                               & .MuiDataGrid-cell .note-button, \
                               & .MuiDataGrid-cell .comments-button, \
                               & .MuiDataGrid-cell .location-button': {
                                  display: 'none',
                              },
                          }
                        : {}),
                }}
                getRowClassName={(params) => (params.row.rarity ? `rarity-${params.row.rarity}` : '')}
                localeText={{
                    columnHeaderSortIconLabel: 'Seřadit',
                }}
                slots={{
                    columnHeaderFilterIconButton: (colDef: { field: string }) => {
                        const isSortColumn = sortModel?.[0]?.field === colDef.field;
                        const activeSortOrder = isSortColumn && sortModel?.[0]?.sort;
                        const firstSortOrder = columns.find((column) => column.field === colDef.field)
                            ?.sortingOrder?.[0];

                        return (
                            <Tooltip
                                title={
                                    colDef === undefined
                                        ? 'Seřadit'
                                        : colDef.field === 'rarity'
                                        ? raritySortLabel
                                        : colDef.field === 'taxonId'
                                        ? taxonSortLabel
                                        : colDef.field === 'count'
                                        ? countSortLabel
                                        : 'Seřadit'
                                }
                            >
                                <IconButton
                                    className="fakeSortButton"
                                    sx={{
                                        display: activeSortOrder ? 'inline-flex' : 'none',
                                        opacity: activeSortOrder ? 1 : 0.4,
                                    }}
                                    size="small"
                                >
                                    {activeSortOrder === 'asc' && <ArrowUpward fontSize="inherit" />}
                                    {activeSortOrder === 'desc' && <ArrowDownward fontSize="inherit" />}
                                    {!activeSortOrder && firstSortOrder === 'asc' && <ArrowUpward fontSize="inherit" />}
                                    {!activeSortOrder && firstSortOrder === 'desc' && (
                                        <ArrowDownward fontSize="inherit" />
                                    )}
                                </IconButton>
                            </Tooltip>
                        );
                    },
                }}
            />
        </Box>
    );
};

export default ObsItemsTable;
