import IObsListsResultsComponentProps from '../../../../interfaces/IObsListsResultsComponentProps';
import { useEffect, useState } from 'react';
import {
    DataGrid,
    GridColumnMenu,
    GridColumnMenuItemProps,
    GridColumnMenuProps,
    GridPagination,
    GridRenderCellParams,
} from '@mui/x-data-grid';
import { IAge, IObservationListed, IPlace, ISex, ISort, ITaxon } from '../../../../schemas/interfaces';
import { format } from 'date-fns';
import {
    allDictionaryProvider,
    placesDictionaryProvider,
    taxonsDictionaryProvider,
} from '../../../../services/dictProviders';
import { ListItemIcon, ListItemText, MenuItem, Box, useMediaQuery, Typography, Stack } from '@mui/material';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { countFormatter } from '../../../../services/dataFormatters';
import TableIcons from '../../../../components/TableIcons';
import { ListItemTags } from '../../../../schemas/enums';
import Export from '../Export';
import ItemRarityIcon from '../../../../components/grid/ItemRarityIcon';
import { useApi } from '../../../../services/apiContext';
import { useAuth } from '../../../../services/authenticator';
import useDeepCompareEffect from 'use-deep-compare-effect';
import SecrecyLock from '../../../../components/dicts/SecrecyLock';
import LongLoadingOverlay from '../../../../components/dataGrid/LongLoadingOverlay';
import doubleRowStyle from '../../../../components/dataGrid/doubleRowStyle';
import horizontalScrollShadowStyle from '../../../../components/dataGrid/horizontalScrollShadowStyle';
import TaxonLanguageSwitcher from '../../../../components/formControls/TaxonLanguageSwitcher';
import TaxonFromDict from '../../../../components/dicts/TaxonFromDict';
import TableActionsMenu from '../../../../components/TableActionsMenu';
import FullHeightContent from '../../../../components/dataGrid/FullHeightContent';

interface ObsItemsTableState {
    observations?: IObservationListed[];
    page: number;
    pageSize: number;
    total?: number;
    taxons: ITaxon[];
    sexDicts?: ISex[];
    ageDicts?: IAge[];
    placesDict?: IPlace[];
    sort: ISort;
    loading: boolean;
    openGallery?: number;
}

const ObsItemsTable: React.FunctionComponent<IObsListsResultsComponentProps> = (
    props: IObsListsResultsComponentProps,
) => {
    const [state, setState] = useState<ObsItemsTableState>({
        page: 0,
        pageSize: 50,
        taxons: [],
        sort: { field: 'date', order: 'desc', language: 'cs' },
        loading: false,
    });
    const isMobile = useMediaQuery('(pointer: coarse)');
    const tableDoubleRow = useMediaQuery('(pointer: coarse) and (max-width: 500px)');
    const api = useApi();
    const { isLoggedIn } = useAuth();

    const fetchObservations = (resetPage = false) => {
        if (!props.query || state.loading) return;

        setState((state) => ({ ...state, observations: [], loading: true }));

        // this scrolls every time the table is updated, we do not want this when the user first loads the page
        // scrolling is now handled in ObsListsApp.tsx
        // document.querySelectorAll('body, html').forEach((el) => {
        //     el.scrollTo({ top: isMobile ? 270 : 200, behavior: 'instant' as ScrollBehavior });
        // });

        setTimeout(() => {
            if (!props.query) return;

            api.getObservations({
                page: resetPage ? 1 : state.page + 1,
                pageSize: 50,
                fields: null,
                // todo: use defined fields list when the schema is ready
                // fields: [
                //     'itemId',
                //     'listId',
                //     'date',
                //     'taxonId',
                //     'count',
                //     'observersText',
                //     'note',
                //     'location.territorialUnitId',
                //     'media',
                //     // 'lastCommented',
                //     // 'commentsCount',
                // ],
                filter: props.query,
                sort: [state.sort],
            })
                .then((rs) => {
                    setState((state) => ({
                        ...state,
                        observations: rs.items,
                        page: rs.page - 1,
                        total: rs.itemsTotalCount < 999999 ? rs.itemsTotalCount : undefined,
                        loading: false,
                    }));
                })
                .catch((e) => {
                    setState((state) => ({ ...state, loading: false }));
                    props.onFetchError && props.onFetchError(e);
                });
        }, 300);
    };

    useEffect(() => {
        taxonsDictionaryProvider.onFirstValue().then((taxons) => setState((state) => ({ ...state, taxons: taxons })));
        allDictionaryProvider
            .onFirstValue()
            .then((all) => setState((state) => ({ ...state, sexDicts: all.sex, ageDicts: all.age })));
        placesDictionaryProvider
            .onFirstValue()
            .then((places) => setState((state) => ({ ...state, placesDict: places.items, placeTypes: places.types })));
    }, []);

    useDeepCompareEffect(() => {
        if (isLoggedIn === undefined) return;

        fetchObservations(true);
    }, [props.query, state.sort, state.pageSize, isLoggedIn]);

    useEffect(() => {
        if (!state.observations) return;

        fetchObservations(false);
    }, [state.page]);

    // useDeepCompareEffect(() => {
    //     if (!props.query || isLoggedIn === undefined) return;

    //     setState((state) => ({ ...state, total: undefined }));
    //     api.searchCount({ filter: props.query }, 'items')
    //         .then((rs) => setState((state) => ({ ...state, total: rs.count })))
    //         .catch((e) => console.error(e));
    // }, [props.query, isLoggedIn]);

    return (
        <>
            <Box sx={props.fullScreen ? { width: '100%', height: '100vh' } : { width: '100%' }}>
                <DataGrid<IObservationListed>
                    getRowId={(item) => item.itemId}
                    rows={state.observations || []}
                    loading={state.loading}
                    rowHeight={tableDoubleRow ? 61 : undefined}
                    disableVirtualization={tableDoubleRow}
                    autoHeight={!props.fullScreen}
                    disableRowSelectionOnClick
                    sortingMode="server"
                    sortingOrder={['asc', 'desc']}
                    sortModel={[
                        {
                            field: state.sort.field,
                            sort: state.sort.order,
                        },
                    ]}
                    onSortModelChange={(model) => {
                        if (model.length != 1) return;
                        if (!model[0].sort) throw new Error('Only sorting is supported, not unsorting.');

                        setState((state) => ({
                            ...state,
                            sort: {
                                field: model[0].field,
                                order: model[0].sort as 'asc' | 'desc',
                                language: 'cs',
                            },
                            page: 0,
                        }));
                    }}
                    paginationMode="server"
                    paginationModel={{
                        pageSize: state.pageSize,
                        page: state.page,
                    }}
                    pageSizeOptions={[50]}
                    rowCount={state.total || 99999}
                    onPaginationModelChange={(model) =>
                        setState((state) => ({ ...state, page: model.page, pageSize: model.pageSize }))
                    }
                    columns={[
                        {
                            field: 'rarity',
                            headerName: '',
                            flex: 0.1,
                            disableColumnMenu: true,
                            sortable: false, // TODO: Remove when sorting is implemented
                            renderCell: (params: GridRenderCellParams<IObservationListed>) => {
                                return <ItemRarityIcon item={params.row} />;
                            },
                            align: 'center',
                            cellClassName: 'rarity',
                            headerClassName: 'rarity',
                        },
                        {
                            field: 'date',
                            headerName: 'Datum',
                            sortingOrder: ['desc', 'asc'],
                            sortable: false, // TODO: Remove when sorting is implemented
                            flex: 1,
                            minWidth: 100,
                            disableColumnMenu: true,
                            valueFormatter: (value, row) => (row.date ? format(row.date, 'd. M. yyyy') : ''),
                            cellClassName: 'date',
                            headerClassName: 'date',
                        },
                        {
                            field: 'taxonName',
                            headerName: 'Druh',
                            flex: 2,
                            minWidth: 200,
                            cellClassName: 'taxonName',
                            headerClassName: 'taxonName',
                            sortable: false, // TODO: Remove when sorting is implemented
                            disableColumnMenu: true, // TODO: Remove when sorting is implemented,
                            renderHeader: (params) => {
                                return (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            fontWeight: 'bold',
                                            alignItems: 'center',
                                        }}
                                        className="MuiDataGrid-cellContent"
                                    >
                                        {params.colDef.headerName}
                                        <TaxonLanguageSwitcher />
                                    </Box>
                                );
                            },
                            renderCell: (params: GridRenderCellParams<IObservationListed>) => (
                                <TaxonFromDict
                                    taxonId={params.row.taxonId}
                                    uncertain={params.row.tags?.includes(ListItemTags.uncertain)}
                                    adviceRequested={params.row.tags?.includes(ListItemTags.adviceRequested)}
                                    link={`/item/${params.row.itemId}`}
                                    boxProps={{
                                        sx: {
                                            fontWeight: 'bold',
                                        },
                                    }}
                                />
                            ),
                        },
                        {
                            field: 'count',
                            headerName: 'Počet',
                            flex: 1,
                            minWidth: 80,
                            sortable: false, // TODO: Remove when sorting is implemented
                            sortingOrder: ['desc', 'asc'],
                            disableColumnMenu: true,
                            cellClassName: 'count',
                            headerClassName: 'count',
                            valueGetter: (value, row) =>
                                countFormatter(row.sexId, row.ageId, row.count, state.sexDicts, state.ageDicts),
                        },
                        {
                            field: 'location',
                            headerName: 'Lokalita',
                            flex: 2.6,
                            minWidth: 170,
                            sortable: false, // TODO: Remove when sorting is implemented
                            disableColumnMenu: true,
                            cellClassName: 'location',
                            headerClassName: 'location',
                            renderCell: (params) => {
                                const place = state.placesDict
                                    ? state.placesDict.find((place) => place.id === params.value.territorialUnitId)
                                          ?.name
                                    : 'neznámá lokace';
                                const text = `${params.row.siteName ? params.row.siteName.trim() + ', ' : ''}${
                                    place ? place : 'Loading...'
                                }`;

                                return (
                                    <>
                                        <Box className="MuiDataGrid-cellContent" title={text}>
                                            {text}
                                        </Box>
                                        {!tableDoubleRow && (
                                            <SecrecyLock
                                                secrecyLevel={params.row.secrecyLevel}
                                                secretUntil={params.row.secretUntil}
                                            />
                                        )}
                                    </>
                                );
                            },
                        },
                        {
                            field: 'observersString',
                            headerName: 'Pozorovatelé',
                            flex: 1.5,
                            minWidth: 100,
                            sortable: false, // TODO: Remove when sorting is implemented
                            disableColumnMenu: true,
                            cellClassName: 'observersString',
                            headerClassName: 'observersString',
                            renderCell: (params) =>
                                params.value ? (
                                    <Box className="MuiDataGrid-cellContent" title={params.value}>
                                        {params.value}
                                    </Box>
                                ) : (
                                    <>
                                        <Box className="MuiDataGrid-cellContent" title="pozorovatelé skryti">
                                            <em>pozorovatelé skryti</em>
                                        </Box>
                                        {!tableDoubleRow && (
                                            <SecrecyLock
                                                secrecyLevel={90}
                                                TooltipProps={{ title: 'Pozorovatelé byli autorem skryti.' }}
                                            />
                                        )}
                                    </>
                                ),
                        },
                        ...(isMobile
                            ? []
                            : [
                                  {
                                      field: 'projectsString',
                                      headerName: 'Projekt',
                                      flex: 1,
                                      minWidth: 80,
                                      sortable: false, // TODO: Remove when sorting is implemented
                                      disableColumnMenu: true,
                                      cellClassName: 'projectsString',
                                      headerClassName: 'projectsString',
                                  },
                              ]),
                        {
                            field: 'attributes',
                            headerName: '',
                            flex: 2,
                            minWidth: isMobile ? 200 : 170,
                            sortable: false,
                            disableColumnMenu: true, // TODO: Remove when sorting is implemented
                            cellClassName: 'attributes',
                            headerClassName: 'attributes',
                            valueGetter: (value, row) => {
                                return row;
                            },
                            renderCell: (params: GridRenderCellParams<IObservationListed>) => {
                                if (!params.row) return null;

                                if (tableDoubleRow)
                                    return (
                                        <TableActionsMenu itemId={params.row.itemId} listId={params.row.listPublicId} />
                                    );

                                return (
                                    <TableIcons
                                        media={params.row.media}
                                        itemId={params.row.itemId}
                                        commentsCount={params.row.commentsCount ?? 0}
                                        lastCommented={params.row.lastCommented ? params.row.lastCommented : undefined}
                                        linkUrl={`/list/${params.row.listPublicId}`}
                                        note={params.value.note}
                                    />
                                );
                            },
                        },
                    ]}
                    slots={{
                        columnMenu: (props: GridColumnMenuProps) => (
                            <>
                                {props.colDef.field == 'taxonName' && (
                                    <CustomColumnMenu
                                        {...props}
                                        defaultOrder="asc"
                                        sortField="taxonId"
                                        sortTextAsc="Seřadit dle ID druhu"
                                        sortTextDesc="Seřadit dle ID druhu"
                                        currentSortModel={state.sort}
                                        customOnChange={(field, order) => {
                                            console.log(field, order);
                                            setState((state) => ({ ...state, sort: { field, order, language: 'cs' } }));
                                        }}
                                    />
                                )}
                                {props.colDef.field == 'lastCommented' && (
                                    <CustomColumnMenu
                                        {...props}
                                        defaultOrder="desc"
                                        sortField="lastCommented"
                                        sortTextAsc="Seřadit od&nbsp;nejstaršího komentáře"
                                        sortTextDesc="Seřadit od&nbsp;nejnovějšího komentáře"
                                        currentSortModel={state.sort}
                                        customOnChange={(field, order) => {
                                            console.log(field, order);
                                            setState((state) => ({ ...state, sort: { field, order, language: 'cs' } }));
                                        }}
                                    />
                                )}
                            </>
                        ),
                        noResultsOverlay: () => (
                            <FullHeightContent>
                                <Typography variant="subtitle1" component="h2" textAlign="center">
                                    Kritériím vyhledávání neodpovídají žádná pozorování.
                                </Typography>
                            </FullHeightContent>
                        ),
                        loadingOverlay: () => <LongLoadingOverlay />,
                        pagination: (props) => (
                            <GridPagination
                                {...props}
                                sx={{
                                    ...props.sx,
                                    ...(state.loading
                                        ? { '& .MuiTablePagination-actions': { opacity: 0.2, pointerEvents: 'none' } }
                                        : {}),
                                }}
                                labelDisplayedRows={(props) => {
                                    return (
                                        <Box component="span">
                                            {/* {state.total === 0 && `0 - 0 z 0`}
                                            {!!state.total && (
                                                <>
                                                    {props.from} - {props.to} z {state.total}
                                                </>
                                            )} */}
                                            {/* {state.total === undefined && ( */}
                                            <>
                                                {props.from} - {props.to}
                                                {/* {props.from} - {props.to} z{' '} */}
                                            </>
                                            {/* )} */}
                                            {/* {state.total === undefined && (
                                                <TooltipMobile title="Počet pozorování se počítá. Prosím, vyčkejte.">
                                                    <Box component="span">
                                                        <Loading
                                                            inline
                                                            progressProps={{ size: 16 }}
                                                            boxSx={{ position: 'relative', top: '3px', left: '2px' }}
                                                        />
                                                    </Box>
                                                </TooltipMobile>
                                            )} */}
                                        </Box>
                                    );
                                }}
                            />
                        ),
                    }}
                    sx={{
                        border: 'none',
                        '& .MuiDataGrid-row': {
                            cursor: 'pointer',
                            backgroundColor: 'rgba(255,255,255,0.2)',
                            '&.rarity-remarkable': {
                                backgroundColor: 'rgba(170,170,170,0.2)',
                            },
                            '&.rarity-uncommon': { backgroundColor: 'rgba(95,170,239,0.2)' },
                            '&.rarity-rare': { backgroundColor: 'rgba(248,155,74,0.2)' },
                            '&.rarity-remarkable, &.rarity-uncommon, &.rarity-rare': {
                                '& .MuiDataGrid-cell.taxonName': {
                                    fontWeight: 'bold',
                                },
                            },
                            '&:hover': {
                                backgroundColor: 'rgba(0,0,0,0.07)',
                                '&.rarity-remarkable': {
                                    backgroundColor: 'rgba(170,170,170,0.4)',
                                },
                                '&.rarity-uncommon': { backgroundColor: 'rgba(95,170,239,0.4)' },
                                '&.rarity-rare': { backgroundColor: 'rgba(248,155,74,0.4)' },
                            },
                        },
                        '& .MuiDataGrid-cellContent': {
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                        },
                        '& .MuiDataGrid-cell.rarity': {
                            display: 'flex',
                        },
                        '& .MuiDataGrid-cell:focus, & .MuiDataGrid-columnHeader:focus, \
                        & .MuiDataGrid-cell:focus-within, & .MuiDataGrid-columnHeader:focus-within, \
                        & .MuiDataGrid-cell:active, & .MuiDataGrid-columnHeader:active': {
                            outline: 'none',
                        },
                        ...horizontalScrollShadowStyle,
                        ...(tableDoubleRow
                            ? {
                                  ...doubleRowStyle,
                                  '& .MuiDataGrid-cell.rarity, & .MuiDataGrid-columnHeader.rarity': {
                                      padding: 0,
                                      width: '10% !important',
                                      order: 3,
                                      textAlign: 'center',
                                  },
                                  '& .MuiDataGrid-cell.date, & .MuiDataGrid-columnHeader.date': {
                                      width: '32% !important',
                                      order: 0,
                                  },
                                  '& .MuiDataGrid-cell.taxonName, & .MuiDataGrid-columnHeader.taxonName': {
                                      width: '38% !important',
                                      order: 1,
                                  },
                                  '& .MuiDataGrid-cell.count, & .MuiDataGrid-columnHeader.count': {
                                      width: '20% !important',
                                      textAlign: 'left',
                                      order: 2,
                                  },
                                  '& .MuiDataGrid-cell.location, & .MuiDataGrid-columnHeader.location': {
                                      width: '60% !important',
                                      order: 4,
                                  },
                                  '& .MuiDataGrid-cell.observersString, & .MuiDataGrid-columnHeader.observersString': {
                                      width: '30% !important',
                                      order: 5,
                                  },
                                  '& .MuiDataGrid-cell.attributes, & .MuiDataGrid-columnHeader.attributes': {
                                      width: '10% !important',
                                      padding: 0,
                                      order: 6,
                                      textAlign: 'center',
                                  },
                                  '& .MuiDataGrid-cell .gallery-button, \
                                   & .MuiDataGrid-cell .note-button, \
                                   & .MuiDataGrid-cell .comments-button, \
                                   & .MuiDataGrid-cell .location-button': {
                                      display: 'none',
                                  },
                              }
                            : {}),
                    }}
                    onRowClick={(params, event) => {
                        if (isMobile || (event.target as HTMLElement).tagName.toLowerCase() === 'a') return;

                        window.open(`${window.location.origin}/item/${params.row.itemId}`);
                    }}
                    getRowClassName={(params) => (params.row.rarity ? `rarity-${params.row.rarity}` : '')}
                />
            </Box>
            {!!state.observations && !!state.observations.length && !!props.query && !props.fullScreen && (
                <Box sx={{ position: 'absolute', bottom: '.5rem', left: '1rem' }}>
                    <Export mode="items" filter={props.query} />
                </Box>
            )}
        </>
    );
};

export default ObsItemsTable;

interface CustomColumnMenuProps extends GridColumnMenuProps {
    defaultOrder: 'asc' | 'desc';
    sortField: string;
    sortTextAsc: string;
    sortTextDesc: string;
    currentSortModel: ISort;
    customOnChange: (field: string, order: 'asc' | 'desc') => void;
}

interface CustomColumnMenuItemProps extends GridColumnMenuItemProps {
    customSortHandler: () => void;
    onClick: (e: any) => void;
}

const CustomColumnMenu: React.FunctionComponent<CustomColumnMenuProps> = (props) => {
    const newOrder =
        props.currentSortModel.field == props.sortField
            ? props.currentSortModel.order == 'asc'
                ? 'desc'
                : 'asc'
            : props.defaultOrder;

    const gridColumnProps = { ...props } as any;
    delete gridColumnProps.defaultOrder;
    delete gridColumnProps.sortField;
    delete gridColumnProps.sortTextAsc;
    delete gridColumnProps.sortTextDesc;
    delete gridColumnProps.currentSortModel;
    delete gridColumnProps.customOnChange;

    return (
        <GridColumnMenu
            {...gridColumnProps}
            slots={{
                // Add new item
                columnMenuUserItem: (itemProps: CustomColumnMenuItemProps) => (
                    <MenuItem
                        onClick={(e) => {
                            itemProps.customSortHandler();
                            itemProps.onClick(e);
                        }}
                    >
                        <ListItemIcon>
                            {newOrder == 'asc' ? <ArrowUpward fontSize="small" /> : <ArrowDownward fontSize="small" />}
                        </ListItemIcon>
                        <ListItemText>{newOrder == 'asc' ? props.sortTextAsc : props.sortTextDesc}</ListItemText>
                    </MenuItem>
                ),
                // hide all original items
                columnMenuColumnsItem: null,
                columnMenuFilterItem: null,
                columnMenuSortItem: null,
            }}
            slotProps={{
                columnMenuUserItem: {
                    customSortHandler: () => props.customOnChange(props.sortField, newOrder),
                },
            }}
        />
    );
};
