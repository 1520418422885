import { Box, Button, Grid, IconButton, TextField, Tooltip, Typography, useMediaQuery } from '@mui/material';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import ActivitySelect from '../../../components/formControls/ActivitySelect';
import AgeSelect from '../../../components/formControls/AgeSelect';
import { IObservationFilter, IPredefinedFilter } from '../../../schemas/interfaces';
import SexSelect from '../../../components/formControls/SexSelect';
import ObsListsSearchDate, { EDateMode } from './search/ObsListsSearchDate';
import { obsListSearchValidationFunction } from '../../../services/validators';
import TagSelect from '../../../components/formControls/TagSelect';
import RaritySelect from '../../../components/formControls/RaritySelect';
import SpeciesAutocomplete from '../../../components/formControls/SpeciesAutocomplete';
import { parseFilterToFormData, parseFormDataToFilter } from '../../../services/parsers';
import { schema_filterParameters } from '../../../schemas/schemas';
import PredefinedFilterSelect from './search/PredefinedFilterSelect';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import ObsListsSearchPlace, { EPlaceMode } from './search/ObsListsSearchPlace';
import { Clear, Search } from '@mui/icons-material';
import PoppedControl from '../../../components/formControls/PoppedControl';
import { ProjectCategory, RarityCategory, SexCategory, TagCategory } from '../../../schemas/enums';
import { useAuth } from '../../../services/authenticator';
import AdvancedSearchTour from '../../../components/joyride/AdvancedSearchTour';
import { useJoyride } from '../../../services/joyrideContext';
import ProjectSelect from '../../../components/formControls/ProjectSelect';
import scrollToFirstError from '../../../lib/scrollToFirstError';
import UserFilterSave from './search/UserFilterSave';
import UserFilterDelete from './search/UserFilterDelete';
import { useUser } from '../../../services/userContext';
import ClearableTextField from '../../../components/formControls/ClearableTextField';

export interface IObsListsSearchProps {
    query?: IObservationFilter;
    onSubmit: (query: IObservationFilter) => void;
    onSaved: (filter: IPredefinedFilter) => void;
}

export interface IObsListsSearchState {
    expanded: boolean;
    selectedPredefinedFilter?: IPredefinedFilter;
}

export interface IObsListSearchFormValues extends IObservationFilter {
    _dateStart?: Date;
    _dateEnd?: Date;
    _yearStart?: Date;
    _yearEnd?: Date;
    _dateMode?: EDateMode;
    _dateRelative?: number;
    _predefinedFilter?: string;
    _placeMode?: EPlaceMode;
}

// all fields should be set here but since validation is not necessary for all fields,
// we can skip them
const defaultValues: IObsListSearchFormValues = {
    coordinatesWithRadius: null,
    count: null,
    _dateMode: EDateMode.Relative,
    _placeMode: EPlaceMode.Coordinates,
};

export const ObsListsSearch: React.FunctionComponent<IObsListsSearchProps> = (props: IObsListsSearchProps) => {
    const [state, setState] = useState<IObsListsSearchState>({ expanded: false });
    const { isLoggedIn } = useAuth();
    const { filters } = useUser();
    const { joyrideEvents } = useJoyride();

    useEffect(() => {
        if (!joyrideEvents) return;

        const subscription = joyrideEvents.subscribe((data) => {
            if (data.step.target === '.tour-search-2') setState({ ...state, expanded: false });
        });

        return () => subscription.unsubscribe();
    }, [joyrideEvents]);

    const onSubmit = (values: IObsListSearchFormValues, helpers: FormikHelpers<IObsListSearchFormValues>) => {
        const filterValues = parseFormDataToFilter(values);
        const parsedValues = schema_filterParameters.safeParse(filterValues);

        if (!parsedValues.success) {
            console.error(parsedValues.error);
        } else {
            props.onSubmit(parsedValues.data as IObservationFilter);
        }

        helpers.setSubmitting(false);
    };

    const onPredefinedFilterChange = useMemo(
        () => async (value: string | string[] | null, formikProps: FormikProps<IObsListSearchFormValues>) => {
            if (!filters?.items?.length) return;

            if (!value) return formikProps.setFieldValue('_predefinedFilter', '');

            const filter = filters?.items?.find((filter) => filter.id === value) as IPredefinedFilter | undefined;

            if (filter) {
                formikProps.resetForm({
                    values: {
                        _predefinedFilter: value as string,
                        ...parseFilterToFormData(filter.parameters),
                    },
                });
                setTimeout(() => formikProps.submitForm());
            }
        },
        [filters],
    );

    useEffect(() => {
        const currentQueryFilter = schema_filterParameters.safeParse(props.query);

        const predefinedFilterMatch = currentQueryFilter.success
            ? filters?.items.find(
                  (filter: IPredefinedFilter) =>
                      JSON.stringify(filter.parameters) == JSON.stringify(currentQueryFilter.data),
              )
            : undefined;

        setState({
            ...state,
            selectedPredefinedFilter: predefinedFilterMatch,
        });
    }, [props.query, filters]);

    return (
        <div className="ObsListsSearch">
            <Formik<IObsListSearchFormValues>
                initialValues={{
                    ...defaultValues,
                    _predefinedFilter: state.selectedPredefinedFilter?.id || 'new',
                    ...props.query,
                }}
                enableReinitialize
                validate={obsListSearchValidationFunction}
                validationSchema={toFormikValidationSchema(schema_filterParameters)}
                onSubmit={onSubmit}
            >
                {(formikProps: FormikProps<IObsListSearchFormValues>) => {
                    const { values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue } =
                        formikProps;
                    const advancedFormTouched = formikProps.dirty;

                    return (
                        <Form onSubmit={handleSubmit}>
                            <Grid container spacing={1.75}>
                                <Grid item xs={12} md={9} className="tour-search-1">
                                    <PredefinedFilterSelect
                                        id="_predefinedFilter"
                                        name="_predefinedFilter"
                                        value={advancedFormTouched ? 'new' : values._predefinedFilter || ''}
                                        onChange={(event) => {
                                            onPredefinedFilterChange(event.target.value, formikProps);
                                        }}
                                        disabled={state.expanded}
                                        onBlur={handleBlur}
                                        fullWidth
                                        label="Předdefinované a vlastní filtry"
                                        placeholder="Vyberte z&nbsp;předdefinovaných či vlastních filtrů"
                                        error={touched._predefinedFilter && !!errors._predefinedFilter}
                                        helperText={touched._predefinedFilter && errors._predefinedFilter}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    md={3}
                                    alignContent="center"
                                    display="flex"
                                    className="tour-search-2"
                                >
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        sx={{ width: '100%', height: '53px' }}
                                        onClick={() => setState({ ...state, expanded: !state.expanded })}
                                    >
                                        {state.expanded ? 'Přejít na\xa0základní' : 'Přejít na\xa0pokročilé'}
                                    </Button>
                                </Grid>
                                {state.expanded && (
                                    <Grid item className="tour-advancedsearch-1">
                                        <AdvancedSearchTour />
                                        <Grid container spacing={1.75} sx={{ pt: 1.75 }}>
                                            <Grid item xs={12} md={9} className="tour-advancedsearch-2">
                                                <SpeciesAutocomplete
                                                    multiple
                                                    name="taxonId"
                                                    onChange={(value) => {
                                                        // empty array is not valid for search, thus setting to null instead
                                                        if (Array.isArray(value) && value.length === 0) value = null;

                                                        formikProps.setFieldValue('taxonId', value);
                                                    }}
                                                    onBlur={handleBlur}
                                                    value={values.taxonId || []}
                                                    label="Druhy"
                                                    placeholder="Vyberte jeden nebo více druhů"
                                                    AutocompleteProps={{
                                                        limitTags: 5,
                                                    }}
                                                    TextFieldProps={{
                                                        error: touched.taxonId && !!errors.taxonId,
                                                        helperText: touched.taxonId && errors.taxonId,
                                                    }}
                                                    highlightWhenFilled
                                                    enableLanguageSwitch
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={3} className="tour-advancedsearch-3">
                                                <RaritySelect
                                                    value={values.minRarityLevel || ''}
                                                    id="minRarityLevel"
                                                    name="minRarityLevel"
                                                    onChange={formikProps.handleChange}
                                                    onBlur={formikProps.handleBlur}
                                                    label="Rarita pozorování"
                                                    error={touched.minRarityLevel && !!errors.minRarityLevel}
                                                    helperText={touched.minRarityLevel && errors.minRarityLevel}
                                                    disableEmpty
                                                    highlightWhenFilled
                                                    filterOptions={(options) =>
                                                        options.filter((option) =>
                                                            option.category.includes(RarityCategory.observationsSearch),
                                                        )
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Grid container spacing={1.75} sx={{ pr: 0.625 }}>
                                                    <Grid item xs={12}>
                                                        <ObsListsSearchDate formikProps={formikProps} />
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        container
                                                        xs={12}
                                                        spacing={1.75}
                                                        className="tour-advancedsearch-9"
                                                    >
                                                        <Grid item xs={12}>
                                                            <Typography variant="subtitle2">
                                                                Detaily záznamu:
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            <ClearableTextField
                                                                id="count"
                                                                name="count"
                                                                onChange={(e) => {
                                                                    setFieldValue('count', e.target.value || undefined);
                                                                }}
                                                                onBlur={handleBlur}
                                                                value={values.count || ''}
                                                                className={values.count ? 'nonEmpty' : ''}
                                                                fullWidth
                                                                label="Počet"
                                                                placeholder="Formát: 5; min 5; max 5; >5; <5; 5-10."
                                                                helperText={
                                                                    touched.count &&
                                                                    errors.count &&
                                                                    'Prosím, zadejte ve\xa0formátu: 5; min\xa05; max\xa05; >5; <5; 5-10.'
                                                                }
                                                                error={touched.count && !!errors.count}
                                                                color="secondary"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            <SexSelect
                                                                value={values.sex || []}
                                                                name="sex"
                                                                onChange={formikProps.handleChange}
                                                                onBlur={formikProps.handleBlur}
                                                                label="Pohlaví"
                                                                multiple
                                                                chips
                                                                checkboxes
                                                                error={touched.sex && !!errors.sex}
                                                                helperText={touched.sex && errors.sex}
                                                                disableEmpty
                                                                enableClear
                                                                highlightWhenFilled
                                                                filterOptions={(options) =>
                                                                    options.filter((option) =>
                                                                        option.category.includes(
                                                                            SexCategory.observationsSearch,
                                                                        ),
                                                                    )
                                                                }
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            <AgeSelect
                                                                value={values.age || []}
                                                                name="age"
                                                                onChange={formikProps.handleChange}
                                                                onBlur={formikProps.handleBlur}
                                                                label="Věk"
                                                                chips
                                                                checkboxes
                                                                multiple
                                                                error={touched.age && !!errors.age}
                                                                helperText={touched.age && errors.age}
                                                                disableEmpty
                                                                enableClear
                                                                highlightWhenFilled
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            <ActivitySelect
                                                                value={values.activity || []}
                                                                name="activity"
                                                                onChange={formikProps.handleChange}
                                                                onBlur={formikProps.handleBlur}
                                                                label="Aktivita"
                                                                chips
                                                                checkboxes
                                                                multiple
                                                                error={touched.activity && !!errors.activity}
                                                                helperText={touched.activity && errors.activity}
                                                                disableEmpty
                                                                enableClear
                                                                highlightWhenFilled
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            <TagSelect
                                                                value={values.tags || []}
                                                                id="tags"
                                                                name="tags"
                                                                onChange={formikProps.handleChange}
                                                                onBlur={formikProps.handleBlur}
                                                                label="Atributy pozorování"
                                                                chips
                                                                checkboxes
                                                                multiple
                                                                error={touched.tags && !!errors.tags}
                                                                helperText={touched.tags && errors.tags}
                                                                disableEmpty
                                                                enableClear
                                                                highlightWhenFilled
                                                                filterOptions={(options) =>
                                                                    options.filter(
                                                                        (option) =>
                                                                            // must be enabled for seach
                                                                            option.category.includes(
                                                                                TagCategory.observationsSearch,
                                                                            ) ||
                                                                            // or user is logged in and tag is enabled for logged in users
                                                                            (isLoggedIn &&
                                                                                option.category.includes(
                                                                                    TagCategory.observationsSearchLoggedIn,
                                                                                )),
                                                                    )
                                                                }
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            <ProjectSelect
                                                                value={values.projects || []}
                                                                id="projects"
                                                                name="projects"
                                                                onChange={(event) => {
                                                                    setFieldValue(
                                                                        'projects',
                                                                        event.target.value
                                                                            ? [event.target.value]
                                                                            : null,
                                                                    );
                                                                }}
                                                                filterOptions={(options) =>
                                                                    options.filter((o) =>
                                                                        o.category.includes(
                                                                            ProjectCategory.observationsSearch,
                                                                        ),
                                                                    )
                                                                }
                                                                onBlur={formikProps.handleBlur}
                                                                label="Projekt"
                                                                error={touched.projects && !!errors.projects}
                                                                helperText={touched.projects && errors.projects}
                                                                highlightWhenFilled
                                                                disableEmpty
                                                                enableClear
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            <Tooltip
                                                                title="Napište jméno či část jména pozorovatele"
                                                                placement="top"
                                                            >
                                                                <Box>
                                                                    <ClearableTextField
                                                                        id="observer"
                                                                        name="observer"
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        value={values.observer || ''}
                                                                        label="Jméno pozorovatele"
                                                                        className={values.observer ? 'nonEmpty' : ''}
                                                                        fullWidth
                                                                        error={touched.observer && !!errors.observer}
                                                                        helperText={touched.observer && errors.observer}
                                                                        color="secondary"
                                                                    />
                                                                </Box>
                                                            </Tooltip>
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            <Tooltip
                                                                title="Napište klíčová slova poznámky"
                                                                placement="top"
                                                            >
                                                                <Box>
                                                                    <ClearableTextField
                                                                        id="note"
                                                                        name="note"
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        value={values.note || ''}
                                                                        label="Klíčová slova poznámky"
                                                                        className={values.note ? 'nonEmpty' : ''}
                                                                        fullWidth
                                                                        error={touched.note && !!errors.note}
                                                                        helperText={touched.note && errors.note}
                                                                        color="secondary"
                                                                    />
                                                                </Box>
                                                            </Tooltip>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12} md={6}>
                                                <ObsListsSearchPlace formikProps={formikProps} />
                                            </Grid>

                                            <Grid
                                                item
                                                xs={12}
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'flex-end',
                                                    alignItems: 'center',
                                                    gap: '1rem',
                                                }}
                                            >
                                                {isLoggedIn === true &&
                                                    (!state.selectedPredefinedFilter || advancedFormTouched) && (
                                                        <UserFilterSave
                                                            filterData={values}
                                                            onSuccess={(newFilter) => {
                                                                props.onSaved(newFilter);
                                                            }}
                                                        />
                                                    )}
                                                {state.selectedPredefinedFilter &&
                                                    state.selectedPredefinedFilter.type === 'userDefined' &&
                                                    !advancedFormTouched && (
                                                        <UserFilterDelete
                                                            onDeleted={() => {
                                                                setState((state) => ({
                                                                    ...state,
                                                                    selectedPredefinedFilter: undefined,
                                                                }));
                                                            }}
                                                            filter={state.selectedPredefinedFilter}
                                                        />
                                                    )}
                                                <PoppedControl
                                                    renderIcon={() => (
                                                        <Tooltip title="Vyčistit filtr" placement="bottom">
                                                            <IconButton
                                                                color="primary"
                                                                sx={{
                                                                    height: '53px',
                                                                    width: '53px',
                                                                }}
                                                                className="tour-advancedsearch-10"
                                                            >
                                                                <Clear fontSize="inherit" />
                                                            </IconButton>
                                                        </Tooltip>
                                                    )}
                                                    renderControl={() => null}
                                                    onConfirmed={() => {
                                                        formikProps.setValues({
                                                            _dateMode: EDateMode.Range,
                                                            _placeMode: EPlaceMode.Coordinates,
                                                        });
                                                    }}
                                                    title="Opravdu si přejete vyčistit celý filtr?"
                                                    confirmButtonLabel="Ano"
                                                    cancelButtonLabel="Zpět"
                                                />
                                                <Button
                                                    variant="contained"
                                                    color="secondary"
                                                    sx={{ height: '53px', width: { xs: '100%', md: '20%' } }}
                                                    onClick={() => {
                                                        // scroll to first errorneous form field
                                                        scrollToFirstError();
                                                        handleSubmit();
                                                    }}
                                                    startIcon={<Search fontSize="large" />}
                                                    className="tour-advancedsearch-11"
                                                >
                                                    Vyhledat
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )}
                            </Grid>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
};
