import { FC, useState, useEffect } from 'react';
import { ICoordinates, IList, IListItem, IPlace } from '../../schemas/interfaces';
import { placesDictionaryProvider } from '../../services/dictProviders';
import { Marker, Popup } from 'react-leaflet';
import CopyToClipboard from './CopyToClipboard';
import OpenStreetMap, { MapCategory, customIcon } from './OpenStreetMap';
import ObservationWithObserver from './ObservationWithObserver';
import ObservationTracks from './ObservationTracks';
import ObservationsFitBounds from '../../apps/ObsListsApp/components/tabs/ObservationsFitBounds';

const ObservationMap: FC<{ list: IList; item?: IListItem }> = ({ list, item }) => {
    const [state, setState] = useState<{ placesDict?: IPlace[]; municipality?: IPlace }>({});

    useEffect(() => {
        placesDictionaryProvider
            .onFirstValue()
            .then((response) => setState((state) => ({ ...state, placeDict: response.items })));
    }, []);

    useEffect(() => {
        setState((state) => ({
            ...state,
            municipality: state.placesDict?.find((place) => place.id == list?.location.territorialUnitId),
        }));
    }, [state.placesDict]);

    const itemCoords: ICoordinates | undefined =
        item?.coordinates?.latitude && item?.coordinates?.longitude
            ? [item.coordinates.latitude, item.coordinates.longitude]
            : undefined;
    const listCoords: ICoordinates | undefined =
        list.location?.coordinates?.latitude && list.location?.coordinates?.longitude
            ? [list.location?.coordinates?.latitude, list.location?.coordinates?.longitude]
            : undefined;
    const municipalityCoords: ICoordinates | undefined =
        state.municipality && state.municipality.latitude && state.municipality.longitude
            ? [state.municipality.latitude, state.municipality.longitude]
            : undefined;

    const markerCoords = itemCoords ?? (!!list.location.coordinatesSetByUser && listCoords);

    return (
        <OpenStreetMap
            center={itemCoords ?? listCoords ?? municipalityCoords}
            defaultZoom={15}
            minHeight="300px"
            maxHeight="450px"
            height="50vh"
            mapCategory={MapCategory.SEARCH}
            enableFullscreen
        >
            {!!item && (
                <>
                    <ObservationWithObserver
                        observer={
                            item.observersCoordinates
                                ? [item.observersCoordinates.latitude, item.observersCoordinates.longitude]
                                : undefined
                        }
                        observation={
                            item.coordinates ? [item.coordinates.latitude, item.coordinates.longitude] : undefined
                        }
                        taxonId={item.taxonId}
                        observationId={item.id}
                        fitBounds
                        observationPopup
                        observerPopup
                        squareCode={list.location.eea10km ?? undefined}
                    />
                </>
            )}
            {!item && (
                <>
                    <ObservationTracks track={list.track} plannedTrack={list.plannedTrack} />
                    {markerCoords && (
                        <Marker position={markerCoords} icon={customIcon}>
                            <Popup>
                                <CopyToClipboard
                                    label="Souřadnice"
                                    value={`${Math.round(markerCoords[0] * 10000000) / 10000000}, ${
                                        Math.round(markerCoords[1] * 10000000) / 10000000
                                    }`}
                                />
                                <br />
                                {list.location.eea10km && (
                                    <CopyToClipboard label="Kód kvadrantu" value={`${list.location.eea10km}`} />
                                )}
                            </Popup>
                        </Marker>
                    )}
                    {!!list.items.length && (
                        <>
                            <ObservationsFitBounds
                                observations={list.items.map((item) => ({
                                    location: { coordinates: item.coordinates },
                                }))}
                            />
                            {list.items
                                .filter((item) => item.coordinates)
                                .map((item) => {
                                    return (
                                        <ObservationWithObserver
                                            observer={
                                                item.observersCoordinates
                                                    ? [
                                                          item.observersCoordinates.latitude,
                                                          item.observersCoordinates.longitude,
                                                      ]
                                                    : undefined
                                            }
                                            observation={
                                                item.coordinates
                                                    ? [item.coordinates.latitude, item.coordinates.longitude]
                                                    : undefined
                                            }
                                            taxonId={item.taxonId}
                                            observationId={item.id}
                                            enableHoverHighlight
                                            observationLink
                                        />
                                    );
                                })}
                        </>
                    )}
                </>
            )}
        </OpenStreetMap>
    );
};

export default ObservationMap;
