import { Save } from '@mui/icons-material';
import { Button, Grid, IconButton, TextField, Tooltip } from '@mui/material';
import { FC } from 'react';
import PoppedControl from '../../../../components/formControls/PoppedControl';
import { IObsListSearchFormValues } from '../ObsListsSearch';
import { Form, Formik } from 'formik';
import { IPredefinedFilter } from '../../../../schemas/interfaces';
import { enqueueSnackbar } from 'notistack';
import * as z from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { useApi } from '../../../../services/apiContext';
import { useUser } from '../../../../services/userContext';
import { removeNullableFilterValues } from '../../../../services/parsers';

interface UserFilterSaveProps {
    filterData: IObsListSearchFormValues;
    onSuccess?: (newFilter: IPredefinedFilter) => void;
}

interface UserFilterFormData {
    name: string;
}

const defaultValues: UserFilterFormData = {
    name: '',
};

const validationSchema = z.object({
    name: z.string({ required_error: 'Toto pole je povinné.' }).min(1),
});

const UserFilterSave: FC<UserFilterSaveProps> = ({ filterData, onSuccess }) => {
    const { postUserDefinedFilter } = useApi();
    const { refetchUser } = useUser();

    const handleSubmit = (formValues: UserFilterFormData, closePopup: () => void) => {
        console.log(formValues, filterData);

        postUserDefinedFilter({
            name: formValues.name,
            parameters: removeNullableFilterValues(filterData),
        })
            .then((response) => {
                if (!response.success) throw new Error(response.error?.cs);

                return refetchUser?.().then((refetchedUser) => {
                    closePopup();
                    enqueueSnackbar('Filtr byl úspěšně uložen.', { variant: 'success' });

                    const newFilter = refetchedUser.filters?.items.find((filter) => filter.id === response.uuid);

                    if (newFilter) onSuccess?.(newFilter);
                });
            })
            .catch((error: Error) => {
                enqueueSnackbar(error.message || 'Nepodařilo se uložit filtr.', { variant: 'error' });
                console.error(error);
            });
    };

    return (
        <PoppedControl
            disableButtons
            title="Zadejte jméno pro nový filtr"
            description="Filtr si tímto uložíte mezi své uložené filtry a můžete jej kdykoliv znovu využít ve vyhledávání."
            renderIcon={() => (
                <Tooltip title="Uložit filtr">
                    <IconButton
                        color="primary"
                        sx={{
                            height: '53px',
                            width: '53px',
                        }}
                    >
                        <Save fontSize="inherit" />
                    </IconButton>
                </Tooltip>
            )}
            renderControl={(handleClose) => (
                <Formik<UserFilterFormData>
                    initialValues={{ ...defaultValues }}
                    enableReinitialize
                    onSubmit={(values) => handleSubmit(values, () => handleClose())}
                    validationSchema={toFormikValidationSchema(validationSchema)}
                >
                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => {
                        return (
                            <Form onSubmit={handleSubmit}>
                                <Grid container sx={{ pt: 3 }} spacing={1.5}>
                                    <Grid item xs={12}>
                                        <TextField
                                            name="name"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.name}
                                            error={!!errors.name}
                                            helperText={!!errors.name && !!touched.name && errors.name}
                                            label="Název filtru"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sx={{
                                            display: 'flex',
                                            gap: '1rem',
                                            justifyContent: 'flex-end',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Button variant="text" color="error" onClick={() => handleClose()}>
                                            Zrušit
                                        </Button>
                                        <Button variant="contained" color="primary" type="submit">
                                            Uložit
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Form>
                        );
                    }}
                </Formik>
            )}
        />
    );
};

export default UserFilterSave;
