import { FC } from 'react';
import { ImportListResult } from './ImportListResult';
import { ListItemText, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { Launch } from '@mui/icons-material';

const ImportListSuccess: FC<{ result: ImportListResult }> = ({ result }) => {
    if (!result.success) return null;

    return (
        <ListItemText
            primary={result.message?.cs || 'Import vycházky byl úspěšný.'}
            secondary={
                result.listPublicId && (
                    <Link to={`/list/${result.listPublicId}`}>
                        Detail vycházky
                        <Launch fontSize="inherit" sx={{ pl: 0.25 }} />
                    </Link>
                )
            }
            sx={{
                '& a': {
                    color: 'inherit',
                    display: 'inline-flex',
                    alignItems: 'center',
                },
            }}
        />
    );
};

export default ImportListSuccess;
