import { LoadingButton } from '@mui/lab';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    Box,
    DialogProps,
} from '@mui/material';
import { FunctionComponent, useEffect, useState, MouseEvent } from 'react';

interface PoppedControlProps {
    renderIcon: (handleClose: PoppedControlCloseHandler) => JSX.Element | null;
    renderControl: (handleClose: PoppedControlCloseHandler) => JSX.Element | null;
    title?: string;
    description?: string;
    confirmButtonLabel?: string;
    cancelButtonLabel?: string;
    onCanceled?: () => void;
    onConfirmed?: () => void | Promise<void>;
    DialogProps?: DialogProps;
    onPopOpen?: () => void;
    onPopClose?: () => void;
    disableButtons?: boolean;
    disableFullwidth?: boolean;
    disableClickaway?: boolean;
    confirmButtonDisabled?: boolean;
    cancelButtonDisabled?: boolean;
    open?: boolean;
    disabled?: boolean;
    confirmButtonLoading?: boolean;
}

type PoppedControlCloseHandler = (submit?: boolean) => void;

const PoppedControl: FunctionComponent<PoppedControlProps> = (props) => {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setOpen(props.open || false);
    }, [props.open]);

    const handleClickOpen = (e: MouseEvent) => {
        if (props.disabled) return;
        e.stopPropagation();
        setOpen(true);
        if (props.onPopOpen) props.onPopOpen();
    };

    const handleClose = (submit = false, reason?: Parameters<NonNullable<DialogProps['onClose']>>[1]) => {
        if (props.disableClickaway && reason) return;

        if (!submit) {
            setOpen(false);
            props.onCanceled?.();
            props.onPopClose?.();
        } else {
            const confirmResult = props.onConfirmed?.();
            if ((confirmResult as Promise<void>).then) {
                (confirmResult as Promise<void>).then(() => {
                    setOpen(false);
                    props.onPopClose?.();
                });
            } else {
                setOpen(false);
                props.onConfirmed?.();
                props.onPopClose?.();
            }
        }
    };

    return (
        <>
            <Box onClick={handleClickOpen} component="span">
                {props.renderIcon(handleClose)}
            </Box>
            <Dialog
                open={open}
                onClose={(_, reason) => handleClose(false, reason)}
                fullWidth={!props.disableFullwidth}
                maxWidth="sm"
                disableEscapeKeyDown={props.disableClickaway}
                {...props.DialogProps}
            >
                {props.title !== undefined && <DialogTitle>{props.title}</DialogTitle>}
                <DialogContent sx={{ paddingTop: '8px !important' }}>
                    {props.description !== undefined && <DialogContentText>{props.description}</DialogContentText>}
                    {props.renderControl(handleClose)}
                </DialogContent>
                {!props.disableButtons && (
                    <DialogActions sx={{ pb: 2, px: 3 }}>
                        {props.cancelButtonLabel !== undefined && (
                            <Button onClick={() => handleClose()} disabled={!!props.cancelButtonDisabled}>
                                {props.cancelButtonLabel}
                            </Button>
                        )}
                        <LoadingButton
                            onClick={(e) => {
                                e.stopPropagation();
                                handleClose(true);
                            }}
                            disabled={!!props.confirmButtonDisabled}
                            variant="contained"
                            color="secondary"
                            loading={props.confirmButtonLoading}
                        >
                            {props.confirmButtonLabel || 'Submit'}
                        </LoadingButton>
                    </DialogActions>
                )}
            </Dialog>
        </>
    );
};

export default PoppedControl;
