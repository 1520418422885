import IObsListsResultsComponentProps from '../../../../interfaces/IObsListsResultsComponentProps';
import { useApi } from '../../../../services/apiContext';
import { ISort, IListListed, IPlace } from '../../../../schemas/interfaces';
import { ListTypes } from '../../../../schemas/enums';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { Box, Stack, Tooltip, Typography, useMediaQuery } from '@mui/material';
import { placesDictionaryProvider } from '../../../../services/dictProviders';
import { DataGrid, GridRowParams, GridRenderCellParams, GridPagination } from '@mui/x-data-grid';
import TableIcons from '../../../../components/TableIcons';
import Export from '../Export';
import Loading from '../../../../components/Loading';
import { useAuth } from '../../../../services/authenticator';
import useDeepCompareEffect from 'use-deep-compare-effect';
import SecrecyLock from '../../../../components/dicts/SecrecyLock';
import { TooltipMobile } from '../../../../components/Tooltip';
import LongLoadingOverlay from '../../../../components/dataGrid/LongLoadingOverlay';
import doubleRowStyle from '../../../../components/dataGrid/doubleRowStyle';
import horizontalScrollShadowStyle from '../../../../components/dataGrid/horizontalScrollShadowStyle';
import FullHeightContent from '../../../../components/dataGrid/FullHeightContent';

interface ObsListsTableState {
    lists?: IListListed[];
    loading: boolean;
    page: number;
    pageSize: number;
    sort: ISort;
    total?: number;
    placesDict: IPlace[];
}

const ObsListsTable: React.FunctionComponent<IObsListsResultsComponentProps> = (
    props: IObsListsResultsComponentProps,
) => {
    const [state, setState] = useState<ObsListsTableState>({
        page: 0,
        pageSize: 50,
        sort: { field: 'date', order: 'desc', language: 'cs' },
        loading: false,
        placesDict: [],
    });
    const isMobile = useMediaQuery('(pointer: coarse)');
    const tableDoubleRow = useMediaQuery('(pointer: coarse) and (max-width: 500px)');
    const api = useApi();
    const { isLoggedIn } = useAuth();

    const fetchLists = (resetPage = false) => {
        if (!props.query || state.loading) return;

        setState((state) => ({ ...state, lists: [], loading: true }));

        // this scrolls every time the table is updated, we do not want this when the user first loads the page
        // scrolling is now handled in ObsListsApp.tsx
        // document.querySelectorAll('body, html').forEach((el) => {
        //     el.scrollTo({ top: isMobile ? 270 : 200, behavior: 'instant' as ScrollBehavior });
        // });

        setTimeout(() => {
            if (!props.query) return;

            api.getObservationsLists({
                page: resetPage ? 1 : state.page + 1,
                pageSize: 50,
                filter: props.query,
                sort: [state.sort],
            })
                .then((rs) => {
                    setState((state) => ({
                        ...state,
                        lists: rs.items,
                        page: rs.page - 1,
                        total: rs.itemsTotalCount < 999999 ? rs.itemsTotalCount : undefined,
                        loading: false,
                    }));
                })
                .catch((e) => {
                    setState((state) => ({ ...state, loading: false }));
                    props.onFetchError && props.onFetchError(e);
                });
        }, 300);
    };

    useEffect(() => {
        placesDictionaryProvider
            .onFirstValue()
            .then((places) => setState((state) => ({ ...state, placesDict: places.items, placeTypes: places.types })));
    }, []);

    useDeepCompareEffect(() => {
        if (isLoggedIn === undefined) return;

        fetchLists(true);
    }, [props.query, state.sort, state.pageSize, isLoggedIn]);

    useEffect(() => {
        if (!state.lists) return;

        fetchLists(false);
    }, [state.page]);

    // useDeepCompareEffect(() => {
    //     if (!props.query || isLoggedIn === undefined) return;

    //     setState((state) => ({ ...state, total: undefined }));
    //     api.searchCount({ filter: props.query }, 'lists')
    //         .then((rs) => setState((state) => ({ ...state, total: rs.count })))
    //         .catch((e) => console.error(e));
    // }, [props.query, isLoggedIn]);

    return (
        <>
            <Box sx={{ width: '100%' }}>
                <DataGrid<IListListed>
                    rows={state.lists || []}
                    loading={state.loading}
                    autoHeight
                    rowHeight={tableDoubleRow ? 61 : undefined}
                    disableVirtualization={tableDoubleRow}
                    disableRowSelectionOnClick
                    sortingMode="server"
                    sortingOrder={['asc', 'desc']}
                    sortModel={[
                        {
                            field: state.sort.field,
                            sort: state.sort.order,
                        },
                    ]}
                    onSortModelChange={(model) => {
                        if (model.length != 1) return;
                        if (!model[0].sort) throw new Error('Only sorting is supported, not unsorting.');

                        setState((state) => ({
                            ...state,
                            sort: {
                                field: model[0].field,
                                order: model[0].sort as 'asc' | 'desc',
                                language: 'cs',
                            },
                            page: 0,
                        }));
                    }}
                    paginationMode="server"
                    paginationModel={{
                        pageSize: state.pageSize,
                        page: state.page,
                    }}
                    pageSizeOptions={[50]}
                    rowCount={state.total || 99999}
                    onPaginationModelChange={(model) =>
                        setState((state) => ({ ...state, page: model.page, pageSize: model.pageSize }))
                    }
                    getRowId={(row) => row.publicId}
                    columns={[
                        {
                            field: 'date',
                            headerName: 'Datum',
                            sortingOrder: ['desc', 'asc'],
                            sortable: false, // TODO: Remove when sorting is implemented
                            flex: 1.5,
                            minWidth: 100,
                            disableColumnMenu: true,
                            cellClassName: 'date',
                            headerClassName: 'date',
                            valueFormatter: (value, row) => (row.date ? format(row.date, 'd. M. yyyy') : ''),
                        },
                        {
                            field: 'location',
                            headerName: 'Lokalita',
                            flex: 3,
                            minWidth: 170,
                            sortable: false, // TODO: Remove when sorting is implemented
                            disableColumnMenu: true,
                            cellClassName: 'location',
                            headerClassName: 'location',
                            renderCell: (params) => {
                                const place = state.placesDict
                                    ? state.placesDict.find((place) => place.id === params.value.territorialUnitId)
                                          ?.name
                                    : 'neznámá lokace';
                                const text = `${params.row.siteName ? params.row.siteName.trim() + ', ' : ''}${
                                    place ? place : 'Loading...'
                                }`;

                                return (
                                    <>
                                        <Box className="MuiDataGrid-cellContent" title={text}>
                                            {text}
                                        </Box>
                                        <SecrecyLock
                                            secrecyLevel={params.row.secrecyLevel}
                                            secretUntil={params.row.secretUntil}
                                        />
                                    </>
                                );
                            },
                        },
                        {
                            field: 'speciesCount',
                            headerName: isMobile ? 'Druhy' : 'Počet druhů',
                            disableColumnMenu: true,
                            sortable: false, // TODO: Remove when sorting is implemented
                            cellClassName: 'speciesCount',
                            headerClassName: 'speciesCount',
                            flex: 1,
                            minWidth: 80,
                        },
                        {
                            field: 'individualsCountString',
                            headerName: isMobile ? 'Jedinci' : 'Počet jedinců',
                            disableColumnMenu: true,
                            sortable: false, // TODO: Remove when sorting is implemented
                            cellClassName: 'individualsCountString',
                            headerClassName: 'individualsCountString',
                            flex: 1,
                            minWidth: 80,
                        },
                        {
                            field: 'observersString',
                            headerName: 'Pozorovatelé',
                            sortable: false, // TODO: Remove when sorting is implemented
                            cellClassName: 'observersString',
                            headerClassName: 'observersString',
                            flex: 2,
                            disableColumnMenu: true,
                            minWidth: 120,
                            renderCell: (params) =>
                                params.value ? (
                                    <Box className="MuiDataGrid-cellContent" title={params.value}>
                                        {params.value}
                                    </Box>
                                ) : (
                                    <>
                                        <Box className="MuiDataGrid-cellContent" title="pozorovatelé skryti">
                                            <em>pozorovatelé skryti</em>
                                        </Box>
                                        <SecrecyLock
                                            secrecyLevel={90}
                                            TooltipProps={{ title: 'Pozorovatelé byli autorem skryti.' }}
                                        />
                                    </>
                                ),
                        },
                        {
                            field: 'type',
                            headerName: 'Typ seznamu',
                            sortable: false, // TODO: Remove when sorting is implemented
                            cellClassName: 'type',
                            headerClassName: 'type',
                            flex: 2,
                            minWidth: 120,
                            disableColumnMenu: true,
                            valueFormatter: (value, row) =>
                                row.type === ListTypes.complete
                                    ? 'Kompletní seznam'
                                    : row.type === ListTypes.partial
                                    ? 'Částečný seznam'
                                    : row.type === ListTypes.selected
                                    ? 'Vybrané druhy'
                                    : 'unknown list type',
                        },
                        {
                            field: 'attributes',
                            headerName: '',
                            cellClassName: 'attributes',
                            headerClassName: 'attributes',
                            flex: 1,
                            minWidth: 130,
                            disableColumnMenu: true,
                            sortable: false,
                            renderCell: (params: GridRenderCellParams<IListListed>) => {
                                if (!params.row) return null;

                                return (
                                    <TableIcons
                                        media={params.row.media}
                                        note={params.row.note}
                                        linkUrl={isMobile ? `/list/${params.row.publicId}` : undefined}
                                    />
                                );
                            },
                        },
                    ]}
                    sx={{
                        border: 'none',
                        '& .MuiDataGrid-row': {
                            cursor: 'pointer',
                        },
                        '& .MuiDataGrid-cellContent': {
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                        },
                        '& .MuiDataGrid-cell:focus, & .MuiDataGrid-columnHeader:focus, \
                        & .MuiDataGrid-cell:focus-within, & .MuiDataGrid-columnHeader:focus-within, \
                        & .MuiDataGrid-cell:active, & .MuiDataGrid-columnHeader:active': {
                            outline: 'none',
                        },
                        ...horizontalScrollShadowStyle,
                        ...(tableDoubleRow
                            ? {
                                  ...doubleRowStyle,
                                  '& .MuiDataGrid-cell.date, \
                                  & .MuiDataGrid-columnHeader.date': {
                                      width: '32% !important',
                                      order: 0,
                                  },
                                  '& .MuiDataGrid-cell.location, \
                                  & .MuiDataGrid-columnHeader.location': {
                                      width: '40% !important',
                                      order: 1,
                                  },
                                  '& .MuiDataGrid-cell.type, \
                                  & .MuiDataGrid-columnHeader.type': {
                                      width: '28% !important',
                                      order: 2,
                                  },
                                  '& .MuiDataGrid-cell.speciesCount, \
                                  & .MuiDataGrid-columnHeader.speciesCount': {
                                      width: '20% !important',
                                      order: 3,
                                  },
                                  '& .MuiDataGrid-cell.individualsCountString, \
                                  & .MuiDataGrid-columnHeader.individualsCountString': {
                                      width: '20% !important',
                                      order: 4,
                                  },
                                  '& .MuiDataGrid-cell.observersString,\
                                   & .MuiDataGrid-columnHeader.observersString': {
                                      width: '50% !important',
                                      order: 5,
                                  },
                                  '& .MuiDataGrid-cell.attributes, \
                                  & .MuiDataGrid-columnHeader.attributes': {
                                      width: '10% !important',
                                      order: 6,
                                      textAlign: 'center',
                                  },
                                  '& .MuiDataGrid-cell .gallery-button, \
                                   & .MuiDataGrid-cell .note-button, \
                                   & .MuiDataGrid-cell .comments-button, \
                                   & .MuiDataGrid-cell .location-button': {
                                      display: 'none',
                                  },
                              }
                            : {}),
                    }}
                    onRowClick={(params: GridRowParams<IListListed>) => {
                        if (isMobile) return;

                        window.open(`${window.location.origin}/list/${params.row.publicId}`);
                    }}
                    slots={{
                        noResultsOverlay: () => (
                            <FullHeightContent>
                                <Typography variant="subtitle1" component="h2" textAlign="center">
                                    Kritériím vyhledávání neodpovídají žádné vycházky.
                                </Typography>
                            </FullHeightContent>
                        ),
                        loadingOverlay: () => <LongLoadingOverlay />,
                        pagination: (props) => (
                            <GridPagination
                                {...props}
                                sx={{
                                    ...props.sx,
                                    ...(state.loading
                                        ? { '& .MuiTablePagination-actions': { opacity: 0.2, pointerEvents: 'none' } }
                                        : {}),
                                }}
                                labelDisplayedRows={(props) => {
                                    return (
                                        <Box component="span">
                                            {/* {state.total === 0 && `0 - 0 z 0`}
                                            {!!state.total && (
                                                <>
                                                    {props.from} - {props.to} z {state.total}
                                                </>
                                            )} */}
                                            {/* {state.total === undefined && ( */}
                                            <>
                                                {props.from} - {props.to}
                                                {/* {props.from} - {props.to} z{' '} */}
                                            </>
                                            {/* )} */}
                                            {/* {state.total === undefined && (
                                                <TooltipMobile title="Počet pozorování se počítá. Prosím, vyčkejte.">
                                                    <Box component="span">
                                                        <Loading
                                                            inline
                                                            progressProps={{ size: 16 }}
                                                            boxSx={{ position: 'relative', top: '3px', left: '2px' }}
                                                        />
                                                    </Box>
                                                </TooltipMobile>
                                            )} */}
                                        </Box>
                                    );
                                }}
                            />
                        ),
                    }}
                />
            </Box>
            {!!state.lists && !!state.lists.length && !!props.query && (
                <Box sx={{ position: 'absolute', bottom: '.5rem', left: '1rem' }}>
                    <Export mode="lists" filter={props.query} />
                </Box>
            )}
        </>
    );
};

export default ObsListsTable;
